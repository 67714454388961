import { Container } from './styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { useState, useEffect } from 'react';
import xyz from '../Divya Bhardwaj Resume[1].pdf';
import logoLight from '../../assets/DB logo1 (1).png';
import logoDark from '../../assets/DB logo1 (1).png';

export function Header() {
  const [isActive, setActive] = useState(false);
  const [isLightMode, setIsLightMode] = useState(false);

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    setIsLightMode(html.classList.contains('light'));
  }, []);

  function toggleTheme() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.toggle('light');
    setIsLightMode(html.classList.contains('light'));
  }

  function closeMenu() {
    setActive(false);
  }

  return (
    <Container className="header-fixed">
      <Router>
        <div className="logo-container">
          <img
            src={isLightMode ? logoLight : logoDark}
            alt="Logo"
            className="logo"
          />
        </div>
        <input
          onChange={toggleTheme}
          className="container_toggle"
          type="checkbox"
          id="switch"
          name="mode"
        />
        <label htmlFor="switch">Toggle</label>
        <nav className={isActive ? 'active' : ''}>
          <NavHashLink smooth to="#home" onClick={closeMenu}>
            Home
          </NavHashLink>
          <NavHashLink smooth to="#about" onClick={closeMenu}>
            About me
          </NavHashLink>
          <NavHashLink smooth to="#project" onClick={closeMenu}>
            Project
          </NavHashLink>
          <NavHashLink smooth to="#services" onClick={closeMenu}>
            Services
          </NavHashLink>
          <NavHashLink smooth to="#contact" onClick={closeMenu}>
            Contact
          </NavHashLink>
          
          <a href={xyz} download className="button">
            Resume
          </a>
        </nav>
        <div
          aria-expanded={isActive ? 'true' : 'false'}
          aria-haspopup="true"
          aria-label={isActive ? 'Close menu' : 'Open menu'}
          className={isActive ? 'menu active' : 'menu'}
          onClick={() => setActive(!isActive)}
        ></div>
      </Router>
    </Container>
  );
}
