import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container } from "./styles";

export function Services() {
  const services = [
    { title: "Assignment Help", description: "(for graduation & post-graduation)" },
    { title: "Research Writing", description: "For PhD Students" },
    { title: "Resume Writing", description: "Cover page and SOP writing" },
    // { title: "Website Designing and Maintenance", description: "For Developement" },
    // { title: "Social Media Marketing", description: "For Social Media" },
    { title: "Video Lectures", description: "E-Tutorial Services" },
    { title: "Content Writing", description: "" },
    // { title: "Video and Blog Podcast Interview", description: "" },
    { title: "Book writing and Publication",description: "" },
  ];

  return (
    <Container id="services">
      <h2>Our Services</h2>
      <div className="services-list">
        {services.map((service, index) => (
          <ScrollAnimation key={index} animateIn="flipInX" duration={0.5}>
            <div className="service">
              <div className="service-body">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </Container>
  );
}
