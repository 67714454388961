import { Container } from './styles'
import reactIcon from '../../assets/react-icon.svg'
import linkedin from '../../assets/linkedin.svg'
import githubIcon from '../../assets/github.svg'
import whatsapp from '../../assets/whatsapp.svg'
import telegram from '../../assets/telegram.svg'
import instagramIcon from '../../assets/instagram.svg'
import facebook from '../../assets/facebook-2429746_1280.png'
import pinterest from '../../assets/pinteresticon.png'
import youtube from '../../assets/youtubeicon.png'
import twitter from '../../assets/Twittericon.png'


// import discordIcon from '../../assets/discord.png'

export function Footer() {
  return (
    <Container className="footer">
      {/* <a href="https://divyabharadwaj.in" className="logo">
        <span>www.vinayak</span>
        <span>singh.in</span>
      </a> */}
      <div>
        <p>Copyright DivyaBhardwaj all rights reserved</p>
      </div>
      <div className="social-media">
        <a
          href="https://www.linkedin.com/in/divya-bhardwaj-577572128/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="Linkedin" />
        </a>
        <a
          href="https://github.com/Codedivya/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a>
       
        <a
          href="https://api.whatsapp.com/send/?phone=%2B917819043479&text=Hello+divya"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="Whatsapp" />
        </a>
        <a
          href="https://t.me/Codedivya"
          target="_blank"
          rel="noreferrer"
        >
          <img src={telegram} alt="telegram" />
        </a> 
         {/* <a
          href="https://www.instagram.com/divyabharadwaj.in"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" />
        </a> */}
        {/* <a
          href="https://www.facebook.com/divyabharadwaj.in"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="Instagram" />
        </a> */}
        {/* <a
          href="https://www.pinterest.com/divyabharadwaj.in"
          target="_blank"
          rel="noreferrer"
        >
          <img src={pinterest} alt="pinterest" />
        </a> */}
        {/* <a
          href="https://www.twitter.com/divyabharadwaj.in"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="twitter" />
        </a> */}
        {/* <a
          href="https://www.youtube.com/divyabharadwaj.in"
          target="_blank"
          rel="noreferrer"
        >
          <img src={youtube} alt="youtube" />
        </a> */}
        
      </div>
    </Container>
  )
}
