import styled from "styled-components";

export const Container = styled.section`
  margin-top: 10rem;

  header {
    text-align: center;
    h2 {
      font-size: 4rem;
    }
    p {
      color: var(--green);
      font-weight: 500;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 1.5rem;
    place-items: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      max-width: 40rem;
      gap: 2rem;
      background-color: var(--green);
      border-radius: 1.4rem;
      padding: 1.6rem 2.8rem;
      transition: background-color 0.25s;

      img {
        width: 4rem;
      }

      a {
        color: var(--black);
        font-weight: 500;
      }

      &:hover {
        background-color: var(--pink);
        a {
          color: #FFF;
        }
      }
    }

    .email-box { /* Specific class for email box */
      width: 60%; /* Increase the width of the email box */
      max-width: 40rem; /* Increase the max-width */
      padding: 2rem 3rem; /* Increase padding */
    }
  }

  @media (max-width: 960px) {
    .contacts {
      flex-direction: column;

      div {
        width: 100%;
      }

      .email-box {
        width: 100%; /* Ensure email box takes full width on smaller screens */
      }
    }
  }
`;
