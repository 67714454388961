import React, { useState, FormEvent } from 'react';
import { FormContainer, Input, Button } from './styles'; // Assume you're styling using styled-components

const WhatsAppForm: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [file, setFile] = useState<File | null>(null); // State for file
  const [fileLink, setFileLink] = useState<string>(''); // New state for file link

  // Function to handle file upload
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      // Trigger upload function here to get the file link
      uploadFile(selectedFile);
    }
  };

  // Mock upload function (replace this with actual file upload logic)
  const uploadFile = async (file: File) => {
    try {
      // Assuming you have a backend API or a service like AWS S3 or Firebase
      // For now, let's assume we upload the file and get back a URL
      const mockFileLink = `https://example.com/${file.name}`; // Replace with actual upload logic
      setFileLink(mockFileLink);
      console.log('File uploaded and accessible at:', mockFileLink);
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Build the WhatsApp message
    const whatsappMessage = `Hello, my name is ${name}.\nMy email is: ${email}\nHere is my message: ${message} \nMy phone number is: ${phone}\nHere is the link to the file: ${fileLink}`;

    const whatsappURL = `https://wa.me/917819043479?text=${(whatsappMessage)}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <Input
          id="name"
          type="text"
          placeholder="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Input
          id="email"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          id="message"
          type="text"
          placeholder="Enter your message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <Input
          id="phone"
          type="text"
          placeholder="Enter your contact no"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        {/* File input to select a PDF */}
        <Input
          id="file"
          type="file"
          accept="application/pdf"
          onChange={handleFileChange} // Handle file selection
        />
        <Button type="submit" disabled={!fileLink}>Send via WhatsApp</Button>
      </form>
    </FormContainer>
  );
};

export default WhatsAppForm;
