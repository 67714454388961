// style.ts
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px; /* Further increased width */
  margin: 50px auto; /* Added space above the form */
  padding: 30px; /* Increased padding */
  background-color: rgba(249, 249, 249, 0.8); /* Transparent background */
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Larger shadow */
`;

export const Input = styled.input`
  margin-bottom: 20px; /* Space between fields */
  padding: 12px; /* Increased padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 18px; /* Increased font size */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent background for input fields */
  color: #333; /* Text color */
`;

export const Button = styled.button`
  padding: 12px 20px; /* Increased padding */
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  font-size: 18px; /* Increased font size */
  cursor: pointer;
  align-self: center; /* Center button horizontally */

  &:hover {
    background-color: #45a049;
  }
`;
