import styled from "styled-components";

export const Container = styled.section`
  margin-top: 15rem;
  
  h2 {
    text-align: center;
    font-size: 4rem;
    margin-bottom: 3rem;
  }

  .services-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 1rem;

    .service {
      padding: 2rem 1.8rem;
      background-color: #2b2b2b;
      border-radius: 1.2rem;
      transition: transform 0.25s, background-color 0.25s;
      color: #FFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &:hover {
        transform: translateY(-5px);
        background-color: var(--pink);
      }

      .service-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex-grow: 1;
      }

      h3 {
        font-size: 2.4rem;
        margin-bottom: 1.5rem;
        color: #3498db; /* Blue color for headings */
      }

      p {
        font-size: 1.6rem;
        color: #FFFFFF;
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 960px) {
    .services-list {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 740px) {
    .services-list {
      grid-template-columns: 1fr;
    }
  }
`;
