import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About me</h2>
        </ScrollAnimation>
        
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <h1>Hi There! I am Divya Bhardwaj</h1>
          
          <h1>(Academic Writing, Software, and Tutoring Expert)</h1>
          <br />


          <p>
            Analytical, good at problem-solving, and excellent in maintaining interpersonal relationships. Enthusiastic and can produce results under deadline constraints.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
          I specialize in high-quality academic writing across various disciplines, supported by a dedicated team. I offer guaranteed publication in different domains such as UGC, Scopus, and SCI indexing etc., ensuring your work gains the recognition it deserves.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          {/* <p>
          Analytical, good at problem-solving, and excellent in maintaining interpersonal relationships. Enthusiastic and can produce results under deadline constraints.
          </p> */}
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
          In addition to writing, I provide software solutions and personalized tutoring for students in Class 1-12, helping them excel academically and achieve their goals.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
            Well-developed communication skills with a reputation for unwavering accuracy, credibility, and exceptional presentation skills.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <p>
            Enriched with the ability to learn new concepts & technology within a short span of time.
          </p>
        </ScrollAnimation>
        <br />
        <ScrollAnimation animateIn="fadeInLeft" delay={0.5 * 1000}>
          {/* <p>
            I can also help in journal publication such as UGC Scopus and more.
          </p> */}
        </ScrollAnimation>
      </div>

      <div className="girl-video">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
          <video
            src="https://cdn.pixabay.com/video/2022/02/12/107491-679592539_large.mp4"
            
            autoPlay
            muted
            loop
          
            style={{ width: "100%", height: "auto" }}
          />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
